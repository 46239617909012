<template>
  <div id="categoryPage" class="app-bar-padding-top footer-space">
    <!-- top bar -->
    <CustomAppBar v-if="dealerJSON" @MainMenuClick="$emit('toggleMainMenu')" v-on="$listeners" />
    <!-- voucher overview -->
    <Overview v-if="dealerJSON" :availableCategories="availableCategories" :disableFilter="true" :categoryTitle="categoryTitle" />
    <!-- Footer -->
    <Footer v-if="dealerJSON"></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import Overview from '@/components/overview/Overview.vue';
import CustomAppBar from '@/components/bars/CustomAppBar.vue';
import Footer from '@/components/bars/Footer.vue';
import { mapState } from 'vuex'; // introducing mapState for store

export default {
  name: 'category',
  components: {
    CustomAppBar,
    Overview,
    Footer
  },
  data() {
    return {
      availableCategories:[],
      currentCategoryName: null,
      pageMetaTitle: '',
      categoryTitle: null,
    }
  },
  metaInfo() {
    return {
      title: this.pageMetaTitle
    }
  },
  mounted() {
    this.currentCategoryName = this.$route.meta.category; // grab the current category name from the $route.param
    for (var i = 0; i < this.dealerJSON.categories.length; i++) {
      // only push to array if Category Name matches with alias url in api
      if (this.dealerJSON.categories[i].url_alias === this.currentCategoryName) {
        this.pageMetaTitle = this.dealerJSON.categories[i].category_meta_title;
        if (this.dealerJSON.categories[i].show_category_title == 1) {
          this.categoryTitle = this.dealerJSON.categories[i].category_name;
        } else {
          this.categoryTitle = null;
        }
        this.availableCategories.push(this.dealerJSON.categories[i].id); // for use in VoucherFilter component as a prop, but as array. Taken from Home.vue where all categories of the environment gets pushed in a seperate array called 'allCategories'
      }
    }
    sessionStorage.setItem('pOrigin', this.$route.meta.category); // save page origin to session storage
  },
  computed: mapState({
    dealerJSON: state => state.dealerJSON, // get data from store
    dealerConfigData: state => state.dealerConfigData // get data from store
  })
}
</script>